import React from 'react'
import Footer from '../components/sections/footer'
import Stairs from '../components/transition/stairs'

function Contact() {
  return (
    <Stairs>
        <Footer/>
    </Stairs>
    
  )
}

export default Contact